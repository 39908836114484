Mosaic.addPlugins([require('/home/elektra_lt/projects/elektra/production/releases/53/app/design/frontend/PlugAndSell2/pwa/packages/gtm-new/src/plugin/ProductListPageComponent.plugin.js')]);
import { Fragment, PureComponent } from 'react';

import ProductCard from 'Component/ProductCard';
import { ProductCardContainerProps } from 'Component/ProductCard/ProductCard.type';
import ProductCardBanner from 'Component/ProductCardBanner';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import { ReactElement } from 'Type/Common.type';
import { noopFn } from 'Util/Common';
import { PRODUCTS_PRELOAD_COUNT } from 'Util/Product';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import { AfterPriority } from 'Util/Request/LowPriorityRender';

import { DEFAULT_PLACEHOLDER_COUNT, PROMO_BANNER_GRID_POSITION, PROMO_BANNER_LIST_POSITION } from './ProductListPage.config';
import { ProductListPageComponentProps, ProductListPageComponentState } from './ProductListPage.type';

import './ProductListPage.style';

/**
 * Placeholder for List of category product
 * @class ProductListPage
 * @namespace PlugAndSell2/Component/ProductListPage/Component */
export class ProductListPageComponent extends PureComponent<ProductListPageComponentProps, ProductListPageComponentState> {
    static defaultProps: Partial<ProductListPageComponentProps> = {
        numberOfPlaceholders: DEFAULT_PLACEHOLDER_COUNT,
        wrapperRef: noopFn,
        selectedFilters: {},
        pageNumber: undefined,
        items: [],
        mix: {},
    };

    observer?: IntersectionObserver;

    node?: Element;

    componentDidMount(): void {
        this.startObserving();
    }

    componentDidUpdate(): void {
        this.startObserving();
    }

    componentWillUnmount(): void {
        this.stopObserving();
    }

    containerProps(): Pick<ProductCardContainerProps, 'isPlp'> {
        const { isPlp } = this.props;

        return {
            isPlp,
        };
    }

    startObserving(): void {
        const { items, updatePages, isInfiniteLoaderEnabled } = this.props;

        if (!isInfiniteLoaderEnabled || items.length) {
            return;
        }

        if (this.node && !this.observer && 'IntersectionObserver' in window) {
            const options = {
                rootMargin: '0px',
                threshold: 0.1,
            };

            this.observer = new IntersectionObserver(([{ intersectionRatio }]) => {
                const { items, isLoading } = this.props;

                // must not be a product items list, and must not be loading
                if (intersectionRatio > 0 && !items.length && !isLoading) {
                    this.stopObserving();
                    updatePages();
                }
            }, options);

            this.observer.observe(this.node);
        }
    }

    stopObserving(): void {
        if (this.observer) {
            if (this.observer.unobserve && this.node) {
                this.observer.unobserve(this.node);
            }

            if (this.observer.disconnect) {
                this.observer.disconnect();
            }

            this.observer = undefined;
        }
    }

    renderPlaceholders(): ReactElement {
        const {
            numberOfPlaceholders = DEFAULT_PLACEHOLDER_COUNT,
            mix: { mods: { layout = CategoryPageLayout.GRID } = {} },
        } = this.props;

        return Array.from({ length: numberOfPlaceholders }, (_, i) => {
            if (i < PRODUCTS_PRELOAD_COUNT) {
                return <ProductCard key={i} product={{}} layout={layout as CategoryPageLayout} onLoad={setLoadedFlag} />;
            }

            return (
                <AfterPriority fallback={<div style={{ minHeight: 200 }} />}>
                    <ProductCard key={i} product={{}} layout={layout as CategoryPageLayout} onLoad={setLoadedFlag} />
                </AfterPriority>
            );
        });
    }

    getPlaceholderRef(): ((node: Element | null) => void) | undefined {
        const { isVisible } = this.props;

        if (!isVisible) {
            return undefined;
        }

        return (node) => {
            this.node = node || undefined;
        };
    }

    renderPageItems(): ReactElement {
        const {
            items,
            mix: { mods: { layout = CategoryPageLayout.GRID } = {} },
            tilePromoBanner,
            device,
        } = this.props;

        return items.map((product, i) => {
            const position = layout === CategoryPageLayout.GRID ? PROMO_BANNER_GRID_POSITION : PROMO_BANNER_LIST_POSITION;
            const shouldRenderInCurrentPosition = (items.length > position && i === position) || (items.length <= position && items.length === i + 1);
            const renderPromoBanner = shouldRenderInCurrentPosition && tilePromoBanner && !device.isTablet && !device.isMobile;

            if (i < PRODUCTS_PRELOAD_COUNT) {
                return (
                    <Fragment key={i}>
                        {renderPromoBanner ? (
                            <ProductCardBanner banner={tilePromoBanner} key={`banner_${i}`} layout={layout as CategoryPageLayout} />
                        ) : null}
                        <ProductCard product={product} layout={layout as CategoryPageLayout} {...this.containerProps()} onLoad={setLoadedFlag} />
                    </Fragment>
                );
            }

            return (
                <AfterPriority fallback={<div style={{ minHeight: 200 }} />}>
                    <Fragment key={i}>
                        {renderPromoBanner ? (
                            <ProductCardBanner banner={tilePromoBanner} key={`banner_${i}`} layout={layout as CategoryPageLayout} />
                        ) : null}
                        <ProductCard product={product} layout={layout as CategoryPageLayout} {...this.containerProps()} onLoad={setLoadedFlag} />
                    </Fragment>
                </AfterPriority>
            );
        });
    }

    renderPlaceholderItems(): ReactElement {
        return (
            <>
                <li block="ProductListPage" elem="Offset" ref={this.getPlaceholderRef()} />
                {this.renderPlaceholders()}
            </>
        );
    }

    renderItems(): ReactElement {
        const { items, isLoading } = this.props;

        if (!items.length || isLoading) {
            return this.renderPlaceholderItems();
        }

        return this.renderPageItems();
    }

    render(): ReactElement {
        const { pageNumber, wrapperRef, mix } = this.props;

        return (
            <ul block="ProductListPage" mix={{ ...mix, elem: 'Page' }} key={pageNumber} ref={wrapperRef}>
                {this.renderItems()}
            </ul>
        );
    }
}

export default ProductListPageComponent;
